import {
    Box,
    Collapse,
    Container,
    Divider,
    List,
    ListItem,
    Paper,
    Typography,
    CircularProgress,
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import React, { useContext, useEffect, useState } from 'react';

import CodeViewer from '../../components/assignment/programming/code-viewer';
import CustomButton from '../../components/button';
import {
    getProgrammingAssignmentById,
    getProgrammingAssignmentSubmissionById,
} from '../../http-actions/assignment-actions';
import {
    updateResolvedStatusForHelpRequest,
    getHelpRequestById,
    getHelpRequestsForUser2,
    getHelpRequestResponses,
} from '../../http-actions/help-request-actions';
import { AuthContext } from '../../providers/auth-provider';
import { postAnalyticsData } from '../../http-actions/analytics-actions';
import HelpRequestResponseForm from '../../components/help-request/view/help-request-response-form';
import HelpRequestResponseList from '../../components/help-request/view/help-request-response-list';
import { HelpRequestResponseI } from '../../components/help-request/types';

// TODO: CLEAR UP STRUCTURE OF BOX-COMPONENTS INTO STYLES DEFINITIONS
// TODO: SEPARATE INTO COMPONENTS

const HelpRequestPage = () => {
    const { state: authState } = useContext(AuthContext);
    const { i18n } = useTranslation();
    const PAGE_LANGUAGE = i18n?.language || 'en';
    const [helpRequests, setHelpRequests] = useState(undefined);
    const [selectedHelpRequest, setSelectedHelpRequest] = useState();

    const fetchHelpRequestForUser = () => {
        void getHelpRequestsForUser2(authState.token).then(async (responseData) => {
            if (responseData.err) return;
            setHelpRequests(responseData);
        });
    };

    const updateResolvedStatus = (helpRequest) => {
        void getHelpRequestById(authState.token, helpRequest?.id).then(async (responseData) => {
            if (responseData.err) return;
            helpRequest.isResolved = responseData.isResolved;
            setHelpRequests([...helpRequests]); // force re-render
        });
    };

    useEffect(() => {
        if (!authState.token) {
            console.log('No token for accessing templates');
            return;
        }
        setHelpRequests(undefined);
        setSelectedHelpRequest(undefined);

        fetchHelpRequestForUser();
        return () => {
            console.log('Remove stuff');
        };
    }, [authState.token]);

    const addRelatedDataToHelpRequest = async (helpRequest) => {
        // no need to update submission
        if (helpRequest.submission && helpRequest.answers) {
            return;
        }

        // fetch submission
        const submissionId = helpRequest.programmingAssignmentSubmissionId;
        const submission = await getProgrammingAssignmentSubmissionById(
            authState.token,
            submissionId,
        );

        if (!submission) return;
        const programmingAssignmentId = submission.programmingAssignmentId;
        const programmingAssignment = await getProgrammingAssignmentById(
            authState.token,
            programmingAssignmentId,
        );

        const answers =
            helpRequest?.content[PAGE_LANGUAGE]?.answers &&
            Object.entries(helpRequest.content[PAGE_LANGUAGE].answers);

        helpRequest.answers = answers;
        helpRequest.submission = { ...submission, programmingAssignment };
    };

    const addResponsesToHelpRequest = async (helpRequest) => {
        const responses = await getHelpRequestResponses(authState.token, helpRequest.id);
        helpRequest.programmingAssignmentHelpRequestResponses = responses;
    };

    const markResponsesAsSeen = (helpRequest) => {
        helpRequest.unseenResponseCount = 0;
    };

    const handleHelpRequestSelect = (helpRequest) => async () => {
        if (selectedHelpRequest?.id === helpRequest?.id) {
            setSelectedHelpRequest(undefined);
            return;
        }
        markResponsesAsSeen(helpRequest);
        await addRelatedDataToHelpRequest(helpRequest); // move to help request component?
        await addResponsesToHelpRequest(helpRequest);
        setSelectedHelpRequest(helpRequest);
        if (authState.token) {
            const helpRequestResponseIds =
                helpRequest.programmingAssignmentHelpRequestResponses.map(
                    (response) => response.id,
                );
            void postAnalyticsData(authState.token, 'help-request-accessed', {
                helpRequestId: helpRequest.id,
                helpRequestUuid: helpRequest.helpRequestUuid,
                isResolved: helpRequest.isResolved,
                assignmentCompleted: helpRequest.assignmentCompleted,
                resolvedManually: helpRequest.resolvedManually,
                helpRequestResponseIds,
            });
        }
    };

    const handleMarkHelpRequestResolved = () => {
        if (!selectedHelpRequest) return;
        void updateResolvedStatusForHelpRequest(authState.token, selectedHelpRequest?.id, {
            resolvedManually: true,
        })
            .then((responseData) => {
                console.log('Resolve update response', responseData);
                updateResolvedStatus(selectedHelpRequest);
            })
            .catch((err) => console.log('Could not update resolved_manually status', err));
    };

    const createTabElement = (name, code) => [
        name?.toUpperCase(),
        <div key={`code-view-${name}`} className="gatsby-highlight" data-language={'dart'}>
            <pre className={'language-dart'}>
                <code className={'language-dart'}>{code}</code>
            </pre>
        </div>,
    ];
    const getTabElements = (submission) => {
        if (submission) {
            return Object.entries(submission.code)?.map(([name, code]) =>
                createTabElement(name, code),
            );
        }
        return [['No code', '']];
    };

    return (
        <Container maxWidth="xl" sx={{ height: 'calc(100vh - 64px)' }}>
            <Box className="help-request-page-header" p={2}>
                <Typography variant="h1" gutterBottom>
                    <Trans i18nKey="helpRequests" />
                </Typography>
                <Divider />
            </Box>
            <Box display="grid" gridTemplateColumns="1fr 1fr" style={{ height: '80%' }}>
                <HelpRequestList
                    helpRequests={helpRequests}
                    selectedHelpRequest={selectedHelpRequest}
                    getTabElements={getTabElements}
                    handleHelpRequestSelect={handleHelpRequestSelect}
                />
                <Box
                    className="help-request-responses-container"
                    mx={2}
                    display="flex"
                    flexDirection="column"
                    overflow="hidden"
                >
                    {selectedHelpRequest ? (
                        <>
                            <Box m={2}>
                                <Box
                                    data-e2e-hook={`help-request-header-${selectedHelpRequest?.helpRequestUuid}`}
                                    className="help-request-row"
                                    display="flex"
                                    width="100%"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    mb={2}
                                >
                                    <Box
                                        className="help-request-header"
                                        flexDirection="column"
                                        display="flex"
                                        width="100%"
                                    >
                                        <Typography variant="h3" className="help-request-title">
                                            {`${selectedHelpRequest?.submission?.programmingAssignment?.title}`}
                                        </Typography>
                                        <StatusTags node={selectedHelpRequest} />
                                        <Typography className="help-request-uuid-subtitle">
                                            {`ID: ${selectedHelpRequest?.helpRequestUuid}`}
                                        </Typography>
                                    </Box>
                                    <Box className="help-request-created-at">
                                        {new Date(selectedHelpRequest?.createdAt).toLocaleString()}
                                    </Box>
                                </Box>
                                <Divider />
                            </Box>

                            <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                width="100%"
                                px={2}
                                pb={2}
                            >
                                <Typography variant="h2">
                                    <Trans i18nKey="responses" />
                                </Typography>
                                <Box>
                                    <CustomButton
                                        data-e2e-hook="help-request-response-mark-resolved-button"
                                        kind="fitech"
                                        variant="contained"
                                        color="primary"
                                        disabled={
                                            selectedHelpRequest?.isResolved || !selectedHelpRequest
                                        }
                                        onClick={handleMarkHelpRequestResolved}
                                    >
                                        <Trans i18nKey="markAsResolved" />
                                    </CustomButton>
                                </Box>
                            </Box>
                            <Box sx={{ overflowX: 'auto', overflowY: 'scroll' }}>
                                <HelpRequestResponseList
                                    responses={
                                        selectedHelpRequest?.programmingAssignmentHelpRequestResponses
                                    }
                                    helpRequestUserId={selectedHelpRequest?.userId}
                                />
                                {selectedHelpRequest?.programmingAssignmentHelpRequestResponses
                                    ?.length > 0 &&
                                    !selectedHelpRequest?.isResolved && (
                                        <HelpRequestResponseForm
                                            selectedHelpRequest={selectedHelpRequest}
                                            onComplete={() =>
                                                addResponsesToHelpRequest(selectedHelpRequest).then(
                                                    () => setHelpRequests([...helpRequests]), // force re-render
                                                )
                                            }
                                        />
                                    )}
                            </Box>
                            {selectedHelpRequest?.programmingAssignmentHelpRequestResponses?.length > 0 && (
                                <Typography component='div'>
                                    <Box sx={{ p: 2, fontStyle: 'italic', textAlign: 'center' }}>
                                        <Trans i18nKey="createNewHelpRequestIfOldOneDidNotHelp" />
                                    </Box>
                                </Typography>
                            )}
                        </>
                    ) : (
                        <Box px={2}>
                            <Typography variant="subtitle1" gutterBottom>
                                <Trans i18nKey="selectHelpRequest" />
                            </Typography>
                        </Box>
                    )}
                </Box>
            </Box>
        </Container>
    );
};

const HelpRequestList = ({
    helpRequests,
    selectedHelpRequest,
    handleHelpRequestSelect,
    getTabElements,
}) => {
    if (!helpRequests) {
        return (
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                width="100%"
                pt={2}
            >
                <CircularProgress />
            </Box>
        );
    }
    if (Array.isArray(helpRequests) && helpRequests.length === 0) {
        return (
            <Box m={2}>
                <Typography variant="subtitle1">
                    <Trans i18nKey="noHelpRequests" />
                </Typography>
            </Box>
        );
    }

    return (
        <Box mx={2} style={{ overflowY: 'scroll' }}>
            <List>
                {helpRequests
                    .sort((a, b) => new Date(a.createdAt) > new Date(b.createdAt))
                    .map((helpRequestNode) => (
                        <React.Fragment key={helpRequestNode.helpRequestUuid}>
                            <ListItem
                                data-e2e-hook={`help-request-list-item-${helpRequestNode.helpRequestUuid}`}
                                key={helpRequestNode.helpRequestUuid}
                                onClick={handleHelpRequestSelect(helpRequestNode)}
                                button
                                selected={
                                    selectedHelpRequest?.helpRequestUuid ===
                                    helpRequestNode.helpRequestUuid
                                }
                            >
                                <Box
                                    data-e2e-hook={`help-request-header-${helpRequestNode.helpRequestUuid}`}
                                    className="help-request-row"
                                    display="flex"
                                    width="100%"
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <Box
                                        className="help-request-header"
                                        flexDirection="column"
                                        display="flex"
                                        width="100%"
                                    >
                                        <Typography variant="h3" className="help-request-title">
                                            {`${helpRequestNode.assignmentTitle}`}
                                        </Typography>
                                        <StatusTags node={helpRequestNode} />
                                        <Typography className="help-request-uuid-subtitle">
                                            {`ID: ${helpRequestNode.helpRequestUuid}`}
                                        </Typography>
                                    </Box>
                                    <Box className="help-request-created-at">
                                        {new Date(helpRequestNode.createdAt).toLocaleString()}
                                    </Box>
                                </Box>
                            </ListItem>
                            <Collapse
                                in={
                                    selectedHelpRequest?.helpRequestUuid ===
                                    helpRequestNode.helpRequestUuid
                                }
                                key={`help-requests-content-collapse-${helpRequestNode.helpRequestUuid}`}
                            >
                                <Paper className="help-request-content" sx={{ py: 2, m: 2 }}>
                                    <Box width="100%">
                                        {selectedHelpRequest?.answers?.length > 0 ? (
                                            selectedHelpRequest?.answers?.map(([key, value]) => (
                                                <Box
                                                    key={key}
                                                    display="flex"
                                                    flexDirection="column"
                                                    px={2}
                                                    pb={2}
                                                >
                                                    <Typography
                                                        variant="subtitle1"
                                                        gutterBottom
                                                        data-e2e-hook="help-request-content-question-label-text"
                                                    >
                                                        {`${key}:`}
                                                    </Typography>
                                                    <Typography
                                                        data-e2e-hook="help-request-content-question-answer-text"
                                                        gutterBottom
                                                    >
                                                        {value}
                                                    </Typography>
                                                </Box>
                                            ))
                                        ) : (
                                            <Box width="100%" px={2}>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    <Trans i18nKey="noContent" />
                                                </Typography>
                                            </Box>
                                        )}
                                        <Box px={2} mb={1} fontSize="14px">
                                            <CodeViewer
                                                id={
                                                    helpRequestNode?.programmingAssignmentSubmissionId
                                                }
                                                tabElements={getTabElements(
                                                    selectedHelpRequest?.submission,
                                                )}
                                            />
                                        </Box>
                                        <Box px={2} mb={1}>
                                            <Typography className="help-request-uuid-subtitle">
                                                {`ID: ${helpRequestNode.helpRequestUuid}`}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Paper>
                            </Collapse>
                        </React.Fragment>
                    ))}
            </List>
        </Box>
    );
};

const StatusTags = ({ node }) => {
    const hasResponses = node?.responseCount > 0;
    const hasUnseenResponses = node?.unseenResponseCount > 0;
    const isResolved = node?.isResolved;

    const primaryBlue = {
        backgroundColor: '#168AFD',
        color: '#fff',
    };

    const warningOrange = {
        backgroundColor: '#ff983f',
        color: '#000',
    };

    const successGreen = {
        backgroundColor: '#388e3c',
        color: '#fff',
    };

    const somethingSomething = {
        backgroundColor: '#fcf003',
        color: '#000',
    };

    return (
        <Box
            className="help-request-tag-wrapper"
            flexDirection="row"
            alignItems="center"
            display="flex"
            mb={1}
        >
            <Paper
                sx={{
                    ...(hasUnseenResponses
                        ? somethingSomething
                        : hasResponses
                        ? primaryBlue
                        : warningOrange),
                    px: 1,
                    mr: 1,
                }}
                className="help-request-resolved-badge"
                elevation={0}
            >
                {hasUnseenResponses ? (
                    <Typography variant="body2">
                        <Trans i18nKey="unseenResponses" />
                    </Typography>
                ) : hasResponses ? (
                    <Typography variant="body2">
                        <Trans i18nKey="hasResponses" />
                    </Typography>
                ) : (
                    <Typography variant="body2">
                        <Trans i18nKey="noResponses" />
                    </Typography>
                )}
            </Paper>
            <Paper
                sx={{ ...(isResolved ? successGreen : warningOrange), px: 1 }}
                className="help-request-resolved-badge"
                elevation={0}
            >
                {node?.isResolved ? (
                    <Typography variant="body2">
                        <Trans i18nKey="resolved" />
                    </Typography>
                ) : (
                    <Typography variant="body2">
                        <Trans i18nKey="notResolved" />
                    </Typography>
                )}
            </Paper>
        </Box>
    );
};

export default HelpRequestPage;
